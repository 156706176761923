
export function styleEditorDialog(){
    const dialog = elt('div',{})
    return dialog
}

function getRules(){
    const rules = this.elt('div', { className: 'mb-3 rules' })
    
}

function elt(type, props, ...children) {
    const dom = document.createElement(type);
    if (props) {
      for (const [key, value] of Object.entries(props)) {
        if (key.startsWith("data-")) {
          dom.setAttribute(key, value); // Dodaje data- atribute
        } else {
          dom[key] = value; // Postavlja ostale atribute kao i prije
        }
      }
    }
    for (const child of children) {
      if (typeof child !== 'string') dom.appendChild(child);
      else dom.appendChild(document.createTextNode(child));
    }
    return dom;
  }