
import OpenLayersParser from 'geostyler-openlayers-parser'
import locale from '../lang/hr.json'
import Control from 'ol/control/Control.js'
import { updateLegend } from './legend'
import Legend from 'ol-ext/control/Legend'
import { styleEditorDialog } from './styleEditorDialog.js'

export class StyleEditor extends Control {
  constructor(options = {}) {
    const element = document.createElement("div");
    element.className = "style-editor";
    element.innerHTML = `
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#style-editor-accordion-item">
           <i class="fa-regular fa-palette" style="margin-right: 5px;"></i> ${locale.styleEditorTitle}
           </button>
        </h2>
        <div id="style-editor-accordion-item" class="accordion-collapse collapse ${options.show ? 'show' : ''}" data-bs-parent="#index-accordion">
          <div class="accordion-body px-1"></div>
        </div>`;
    super({
      target: options.target,
      element
    })
    this.body = this.element.querySelector('.accordion-body')
    this.parser = new OpenLayersParser()
    //icon canvas dimensions
    this.state = {
      geostyles: [],
      textareaDisplay: options.code || true,
      textareaValue: ''
    }
  }

  setMap(map) {
    super.setMap(map)
    this.map = map
    map.getAllLayers().forEach((x) => {
      const geostyle = x.get('geostyle')
      const category = x.get('category')
      if (x.get('geostyle') && category) {
        this.state.geostyles.push(geostyle)
        geostyle.name = x.get('name')
        geostyle.category = x.get('category')
      }
    })
    this.state.category = this.state.geostyles[0].category // selected category
    this.state.index = 0 // selected geostyle
    this.renderHTML()
  }
  parse(geostyle) {
    this.parser.writeStyle(geostyle)
      .then(r => {
        const layer = this.map.getAllLayers()
          .find(x => x.get('name') === geostyle.name)
        layer.setStyle(r.output)
        layer.set('geostyle', geostyle)
        layer.changed()
        // sinhronize with legend
        updateLegend(this.map.getControls().getArray().find(x => x instanceof Legend))
      })
  }
  handleCategoryRadios(evt) {
    this.state.category = evt.target.dataset.category
    this.renderHTML()
  }
  handleLayerSelector(evt) {
    const layerSelector = document.getElementById('style-editor-select')
    this.state.index = [layerSelector.selectedIndex]
    this.renderHTML()
  }
  handleTextareaButton() {
    const textarea = document.getElementById('style-editor-textarea')
    const layerSelector = document.getElementById('style-editor-select')
    try {
      let geostyle =this.state.geostyles[layerSelector.selectedIndex]
      const newGeostyle = JSON.parse(textarea.value)
      newGeostyle.name = geostyle.name
      newGeostyle.category = geostyle.category
      geostyle = newGeostyle
      
      this.parse(geostyle)
      this.state.textareaValue = geostyle
      this.renderHTML()
    } catch (error) {
      console.error("Failed to parse geostyle:", error, textarea.value);
      textarea.value = this.state.textareaValue;
      document.addEventListener('click', () => {
        document.getElementById('style-editor-textarea').classList.remove('is-invalid')
      });
    }
  }


  attachEventListeners() {
    const categoryRadios = document.querySelector('.style-editor-check')
    categoryRadios.addEventListener('change', evt => this.handleCategoryRadios(evt))
    const layerSelector = document.getElementById('style-editor-select')
    layerSelector.addEventListener('change', evt => this.handleLayerSelector(evt))
    const textareaButton = document.querySelector('.style-editor-button')
    textareaButton.addEventListener('click', () => this.handleTextareaButton())
  }
  renderHTML() {
    this.body.replaceChildren(this.generateHTML())
    this.attachEventListeners()
  }
  generateHTML() {
    const content = this.elt('div')
    // category radio buttons
    const categoryRadios = this.elt('div', { className: 'style-editor-check d-none' })
    const categories = [...new Set(this.state.geostyles.map(item => item.category))]
    categories.forEach((category) => {
      categoryRadios.append(this.elt('div', { className: 'form-check form-check-inline' },
        this.elt('input', {
          className: 'form-check-input', type: 'radio',
          id: `style-editor-check-${category}`, checked: category === this.state.category, name: 'style-editor-check',
          'data-category': category
        }),
        this.elt('label', { className: 'form-check-label', htmlFor: `style-editor-check-${category}` },
          locale[category])
      ))
    })
    if (categories.length > 1) categoryRadios.classList.remove('d-none')
    content.append(categoryRadios)
    // layer selector
    const select = this.elt('select', {
      className: 'form-select form-select-sm mb-2',
      id: 'style-editor-select'
    })
    const geostylesOfCategory = this.state.geostyles.filter(x => x.category === this.state.category)
    geostylesOfCategory.forEach(g => select.add(new Option(locale[g.name], g.name)))
    select.selectedIndex = this.state.index
    const label = this.elt('label', { for: 'style-editor-select' },
      locale.styleEditorSelectLabel)
    const layerSelector = this.elt('div', { className: 'form-floating' }, select, label)
    content.append(layerSelector)
    // textarea with geostyle code
    const textarea = this.elt('textarea', {
      id: 'style-editor-textarea',
      className: 'form-control mb-3',
      style: 'width:100%;height:18.75em;font-size:0.8em'
    })
    if (this.state.textareaDisplay) textarea.classList.remove('d-none')
    else textarea.classList.add('d-none')
    const geostyle = this.state.geostyles[this.state.index]
    textarea.value = JSON.stringify(geostyle, undefined, 4)
    this.state.textareaValue = textarea.value
    
    const textareaButton = this.elt('button', { className: 'btn btn-primary style-editor-button' },
      locale.styleEditorTextareaButton)
    content.append(this.elt('div', {}, textarea, textareaButton))
    if (styleEditorDialog) content.append(styleEditorDialog())
    return content
  }

  elt(type, props, ...children) {
    const dom = document.createElement(type);
    if (props) {
      for (const [key, value] of Object.entries(props)) {
        if (key.startsWith("data-")) {
          dom.setAttribute(key, value); // Dodaje data- atribute
        } else {
          dom[key] = value; // Postavlja ostale atribute kao i prije
        }
      }
    }
    for (const child of children) {
      if (typeof child !== 'string') dom.appendChild(child);
      else dom.appendChild(document.createTextNode(child));
    }
    return dom;
  }
}
